import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { ProviderAttestationService } from '../ProviderAttestation/provider-attestation.service'
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS} from '../../core/constants/index';
import { Page } from 'src/app/app-store/entities/page';
import { IProviderAttestationViewModel, IProviderModel, IProviderSearchModel } from 'src/app/app-store/entities/provider';
import { IPattern } from 'src/app/app-store/entities/formfields';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { IProviderAttestationUser } from 'src/app/app-store/entities/user';
import { ProviderHistoryService } from './provider-history.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-provider-list',
  templateUrl: './provider-list.component.html',
  styleUrls: ['./provider-list.component.scss']
})
export class ProviderListComponent implements OnInit,OnDestroy {
  formGroup!: FormGroup;  
  providerAttestations$: IProviderModel[] =[];
  rows = [];
  selected = [];
  currentPageSize!:number
  currentOrderBy!:string;
  currentOrderByDescending!:string
  currentPageNumber!:number;
  pageSizeOptions:number[]=[];
  patterns$!: IPattern[];
  columns = [      
    {
    prop: 'selected',
    Name: '',
    sortable: true,
    canAutoResize: false,
    draggable: false,
    resizable: false,
    headerCheckboxable: true,
    checkboxable: true,
    width: 30
  }];
  ColumnMode = ColumnMode;
  page = new Page();
  isloading: boolean=false;
  searchParameterData!:IProviderSearchModel;
  totalCount:number = 0;
  assignToList$!:IProviderAttestationUser[];
  @ViewChild(DatatableComponent) table!: DatatableComponent;
  //todo - mq: revert  
  attestationPeriod: string = '2024Q4';
  searchStateSubscripion : Subscription;
    subscribeToRouter : Subscription;
    keyHandlers: { [key: string]: () => void };

  constructor(private fb:FormBuilder,
    private providerAttestationService: ProviderAttestationService,
    private router:Router,
    private route: ActivatedRoute,
    private datePipe:DatePipe,
    private providerHistoryService : ProviderHistoryService) {
    this.page.pageNumber=0;
    this.page.size = DEFAULT_PAGE_SIZE;
    this.keyHandlers = {
      'Enter': () => this.searchProvider(),
    };
   }

  ngOnDestroy(): void {
    this.searchStateSubscripion.unsubscribe();
  }


  ngOnInit(): void {
    this.initializeSearchParams();
    this.initializeForm();
    this.isloading =true;
    this.pageSizeOptions = PAGE_SIZE_OPTIONS;
    this.currentPageSize= this.searchParameterData.pageSize;
    this.currentPageNumber=this.searchParameterData.pageNumber;
    this.currentOrderBy= this.searchParameterData.orderBy;
    this.currentOrderByDescending= this.searchParameterData.orderDescending;  
    this.getAssignToListData(); 
    this.loadData(); 
    this.routerSubscription();
  }
  
  initializeForm()
  {
    this.formGroup = this.fb.group({
      name: [this.searchParameterData.providerName],
      npi: [this.searchParameterData.npi],
      tin: [this.searchParameterData.tin],
      ddlAttestation: new FormControl({}),
      ddlCompleted: new FormControl(),
      ddlAssignedTo: new FormControl(),
      ddlModified: new FormControl(),
      ddlPageSizeOptions:new FormControl(),
    });
    this.formGroup.get('ddlAttestation')?.setValue(this.convertBooleanToSelect(this.searchParameterData.isAttested));
    this.formGroup.get('ddlCompleted')?.setValue(this.convertBooleanToSelect(this.searchParameterData.isCompleted));
    this.formGroup.get("ddlAssignedTo")?.setValue(this.searchParameterData.assignedTo);
    this.formGroup.get('ddlModified')?.setValue(this.convertBooleanToSelect(this.searchParameterData.isModified));
    let ddlPageSizeOptions = this.formGroup.get('ddlPageSizeOptions') as FormControl;
    ddlPageSizeOptions.patchValue(DEFAULT_PAGE_SIZE);

    this.patterns$ = [
      { key:'A', value: new RegExp("[a-zA-Z -]")}, //uppercase and lowercase letters and space only
      { key:"B", value: new RegExp("[0-9]")}, // numerics only
      { key:"C", value: new RegExp("[0-9]")}, // numerics only     
    ];

    this.formGroup.get('name')?.valueChanges.subscribe((value: string) => {
      this.formGroup.get('name')?.setValue(value.replace(/[^A-Za-z -]/, ""), { emitEvent: false });
    });
  

    this.formGroup.get('npi')?.valueChanges.subscribe((value: string) => {
      this.formGroup.get('npi')?.setValue(value.replace(/[^0-9]*/g, ""), { emitEvent: false });
    });

    this.formGroup.get('tin')?.valueChanges.subscribe((value: string) => {
      this.formGroup.get('tin')?.setValue(value.replace(/[^0-9]*/g, ""), { emitEvent: false });
    });
  }

  setSearchParameter(){
    this.searchParameterData= {
      providerName: this.formGroup.get('name')?.value,
      email:'',
      npi:this.formGroup.get('npi')?.value,
      tin:this.formGroup.get('tin')?.value,      
      pageNumber:this.currentPageNumber,
      pageSize:this.currentPageSize,
      orderBy:this.currentOrderBy,
      orderDescending:this.currentOrderByDescending,
      isAttested: ((this.formGroup.get('ddlAttestation')?.value=="")?this.formGroup.get('ddlAttestation')?.value:(this.formGroup.get('ddlAttestation')?.value==1)?true:false),
      isCompleted: ((this.formGroup.get('ddlCompleted')?.value=="")?this.formGroup.get('ddlCompleted')?.value:(this.formGroup.get('ddlCompleted')?.value==1)?true:false),
	    assignedTo:this.formGroup.get('ddlAssignedTo')?.value,
    	isModified:((this.formGroup.get('ddlModified')?.value=="")?this.formGroup.get('ddlModified')?.value:(this.formGroup.get('ddlModified')?.value==1)?true:false),
      //todo - mq: revert  
      attestationPeriod: "2024Q4"
    }             
  }

  getProviderAttestationData(searchParameterData:IProviderSearchModel){
    //get list of providers with attestation details 
    this.isloading=true;
    //todo - mq: revert
    searchParameterData.attestationPeriod = '2024Q4'
    this.providerAttestationService.getProviderAttestationDetails(searchParameterData).subscribe({
        next: async (response :IProviderModel[]) => {          
          this.providerAttestations$ = response;          
          this.totalCount=response[0]?.totalRowCount ?? 0;          
          if (this.providerAttestations$ !=null && this.providerAttestations$ !=undefined && this.providerAttestations$.length>0)
          {            
            this.setTableSorts(this.currentOrderBy, this.currentOrderByDescending); 
            //code to mock the data for cdo, assingto, isModified, iscompleted columns for now
            //we need to have this in later sprint
           //await this.mockdata();          
          }
        },
        error: (e) => {
          console.error('Provider details request failed with error - ' + e);                    
        },
        complete: () => {
          console.info('Request completed !!!')  
          this.isloading=false        
        }
      });
   }

  loadData() {       
    let period: string | null = null; 
    this.route.queryParamMap.subscribe(params => {
       period = params.get('period');      
    });

    let date: string = period ?? this.datePipe.transform(new Date(),"MM-dd-yyyy") ?? "";
    this.providerAttestationService.getCurrentAttestationPeriod(date).subscribe({
      next: async(response: string)=> {    
        //todo - mq: revert          
        //this.attestationPeriod = response;    
        this.attestationPeriod = '2024Q4'    
        this.getProviderAttestationData(this.searchParameterData);

      },
      error: (e) => {
        console.error('Attestation period request failed with error - ' + JSON.stringify(e));                    
      }
    });
  }


  //  mockdata(){
  //   let flag: boolean= true;
  //   for (var i = 0; i < this.providerAttestations$.length; i++) {
  //     flag = !flag;
  //     //this.providerAttestations$[i].providerDetails.cdo='Apple Care';
  //     this.providerAttestations$[i].assignTo='Seema Kamble';
  //     //this.providerAttestations$[i].providerDetails.modified=flag;
  //     //this.providerAttestations$[i].attestationDetails.isCompleted =true;
  //   }  
  //  }
  searchProvider(){    
    this.pageSizeOptions = PAGE_SIZE_OPTIONS;
    this.currentPageNumber=1;  
    this.currentOrderBy='name';
    this.currentOrderByDescending='asc';
    this.totalCount = 0;  
    this.setSearchParameter();
    this.updateSearchParms();
    this.getProviderAttestationData(this.searchParameterData);    
    //call api method here to fetch the data
  }

  resetForm(){
    this.formGroup.get('name')?.setValue('');
    this.formGroup.get('npi')?.setValue('');
    this.formGroup.get('tin')?.setValue('');
    this.formGroup.get('ddlCompleted')?.setValue('');
    this.formGroup.get('ddlAssignedTo')?.setValue('');
    this.formGroup.get('ddlModified')?.setValue('');
    this.formGroup.get('ddlAttestation')?.setValue('');
    this.currentPageNumber = 1;
    this.table.sorts = [{ prop: 'name', dir: 'asc' }];
    this.currentOrderBy='name';
    this.currentOrderByDescending='asc';
    this.setSearchParameter();
    this.updateSearchParms();
    this.getProviderAttestationData(this.searchParameterData);
  }

  showDetails(row:any){
    this.router.navigate(['history/' + row.providerId], {
      state: this.searchParameterData
    })
  }
 
  onPageSizeOptionChange(value: any)
  {
    if (value !=null && value !=undefined)
    {
      //console.log(value.target.value);
      this.currentPageSize = value.target.value;
      this.currentPageNumber = 1;  
      this.setSearchParameter();      
      this.updateSearchParms();
      this.getProviderAttestationData(this.searchParameterData);
    }    
  }

  onSort(event:any){   
     if (event !=null && event!=undefined && event.sorts.length!=0)
     {
      if ( event.sorts[0].prop=='firstName' )
      {
        //sort by provider name
        this.currentOrderBy = 'Name';
        this.currentOrderByDescending = event.sorts[0].dir;
      }
      else
      {
        this.currentOrderBy = (event.sorts[0].prop).replace('providerDetails.','').replace('attestationDetails.','');
        this.currentOrderByDescending = event.sorts[0].dir;
      }       
     }    
     //call api method to get the sorted data for the selected page
     this.currentPageNumber = 1;
     this.setSearchParameter();    
     this.updateSearchParms();   
     this.getProviderAttestationData(this.searchParameterData);     
   
   }
 
   onFooterPage(event:any){    
    // console.log (pageInfo);
    if (event !=null && event !=undefined){
      this.currentPageNumber =(event.page);
      //this.currentPageSize = pageInfo.pageSize;     
    }  
    //call api method to get the data for the selected page  
    this.setSearchParameter();  
    this.updateSearchParms();
    this.getProviderAttestationData(this.searchParameterData);
   }

  keyPressValidation(event:any,key:string){
    let index = this.patterns$.findIndex((obj => obj.key?.toLowerCase() == key.toLowerCase()));
    let isvalid! : boolean;
    if (index !=-1)
    {
      var str = String.fromCharCode(event.keyCode);
      let pattern = this.patterns$[index];       
      if (pattern.value.test(str)) {
        isvalid=true;
      } 
      else 
      {
        event.preventDefault();
        isvalid=false;          
      }
    }
    return isvalid;
  }
  getAssignToListData(){
    //get the list of notification status types
    this.providerAttestationService.getAssignToData().subscribe({
      next: async (response :IProviderAttestationUser[]) => {
        this.assignToList$ = response; 
        // sort status types
        this.assignToList$.sort((a, b) => a.firstName!.localeCompare(b.firstName!))
        // add default status type
        let usr: IProviderAttestationUser = {providerAttestationUserId: "", firstName: "Select", disabled:false, lastName:"", email:"",emailVerified:false,mSIdUserId:"",oneHealthcareIdUserId:"",providerAttestationRoleId:0, userName:"" };
        this.assignToList$.unshift(usr);
      },
      error: (error:any) => {
        console.error('Assign To data request failed with error - ' + error);
      },
      complete: () => {          
        console.info('Assign To data request completed !!!')        
      }
    });
  }
  initializeSearchParams()
  {
    this.searchStateSubscripion = this.providerHistoryService.searchParms$.subscribe(parms => {
      this.searchParameterData = parms;
      });
  }
  convertBooleanToSelect(value : boolean | undefined | string ) : string{
    if(value === undefined || value === '')
      {
        return '';
      }
      return value ? '1'  : '0';
  }
  setTableSorts(prop:string,dir:string )
  {
    if(this.table)
      this.table.sorts = [{ prop: prop, dir: dir }];
  }
  updateSearchParms(){
    this.providerHistoryService.setSearchParms(this.searchParameterData);
  }

  //subscribe to router events and clear search params object if navigating to other pages instead of list and history
  routerSubscription()
  {
    this.subscribeToRouter = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        const navEndEvent = event as NavigationEnd;
        if (!navEndEvent.urlAfterRedirects.includes('history/') && 
        !navEndEvent.urlAfterRedirects.includes('/admin')) {
          this.providerHistoryService.clearSearchParams();
        }
      });
  }
}